<template>
  <div>
    <h1 class="allocation-title h3 mb-3">
      <a-icon type="arrow-left" @click="goBack" />
      {{ $t('product.stock_allocation') }}
    </h1>
    <a-card class="allocation-card">
      <a-row :gutter="16" class="mb-2">
        <a-col :span="4" class="allocation-form">
          {{ $t('product.product_name') }}
        </a-col>
        <a-col :span="20">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
            : {{ product?.detail?.title || '-' }}
          </a-skeleton>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-2">
        <a-col :span="4" class="allocation-form">
          {{ $t('product.varian') }}
        </a-col>
        <a-col :span="20">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
            : {{ `${product?.catalogs?.length || 0} ${$t('product.varian')}` }}
          </a-skeleton>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-2">
        <a-col :span="4" class="allocation-form">
          Channel
        </a-col>
        <a-col :span="20">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
            : {{ `${product?.channels?.length || 0} Channel` }}
          </a-skeleton>
        </a-col>
      </a-row>
      <a-row v-if="permission" :gutter="16" class="mt-3 mb-2">
        <a-col :span="24">
          Set Stok Alokasi
        </a-col>
        <a-col :span="8">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
            <InputNumber v-model="setStock" placeholder="Masukkan Nilai atau Persentase Alokasi" />
          </a-skeleton>
        </a-col>
        <a-col :span="4" class="pr-0 pl-0">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
            <a-button type="primary" @click="calculateAllStock">
              Terapkan ke Semua
            </a-button>
          </a-skeleton>
        </a-col>
      </a-row>
      <a-spin :spinning="loading">
        <a-tabs
          tab-position="top"
          class="allocation-tabs"
          @change="changeTab"
          @prevClick="callback"
          @nextClick="callback"
        >
          <a-tab-pane v-for="(warehouse, windex) in warehouses" :key="warehouse.id" :tab="warehouse.name">
            <div class="allocation-header mb-2">
              <a-row>
                <a-col :span="12">
                  {{ $t('product.catalog') }}
                </a-col>
                <a-col :span="6">
                  {{ $t('productAvailability.instock') }}
                </a-col>
              </a-row>
            </div>
            <div class="allocation-scroll" :style="{ height: permission ? '290px' : '410px' }">
              <a-collapse v-model="activeKey" expand-icon-position="right" class="allocation-list">
                <a-collapse-panel v-for="(catalog, cindex) in warehouse.catalogs" :key="catalog.id">
                  <template slot="header">
                    <a-row>
                      <a-col :span="12">
                        <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
                          {{ catalog.name }}
                          <div class="sku-desc">
                            {{ catalog.sku }}
                          </div>
                        </a-skeleton>
                      </a-col>
                      <a-col :span="6" style="padding-left: 12px">
                        <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
                          {{ formatNumber(catalog.availability) }} PCS
                        </a-skeleton>
                      </a-col>
                    </a-row>
                  </template>
                  <a-row class="mb-2 title">
                    <a-col :span="10">
                      Channel
                    </a-col>
                    <a-col :span="6">
                      Sales Channel
                    </a-col>
                    <a-col :span="4">
                      {{ $t('product.stock_allocation') }}
                    </a-col>
                    <a-col :span="4">
                      {{ $t('product.allocated_stock') }}
                    </a-col>
                  </a-row>
                  <a-row v-for="(stock, sindex) in catalog.allocation" :key="sindex" class="mb-2">
                    <a-col :span="10" :class="{ 'ptb-5': permission }">
                      <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
                        {{ stock.name }}
                      </a-skeleton>
                    </a-col>
                    <a-col :span="6" :class="{ 'ptb-5': permission }">
                      {{ stock.sales_channel }}
                    </a-col>
                    <a-col :span="4">
                      <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
                        <div v-if="permission" style="width: 75%">
                          <InputNumber
                            v-model="stock.allocate_value"
                            addon="%"
                            @change="calculateStock($event, windex, cindex, sindex)"
                          />
                        </div>
                        <div v-else>
                          {{ `${stock?.allocate_value || 0}%` }}
                        </div>
                      </a-skeleton>
                    </a-col>
                    <a-col :span="4" :class="{ 'ptb-5': permission }">
                      <a-skeleton active :loading="loading" :paragraph="{ rows: 0 }">
                        {{ formatNumber(stock.availability) }} {{ stock.uom }}
                      </a-skeleton>
                    </a-col>
                  </a-row>
                  <a-row v-if="!catalog.allocation.length" class="mb-2">
                    <a-col :span="12">
                      -
                    </a-col>
                    <a-col :span="6">
                      -
                    </a-col>
                    <a-col :span="6">
                      -
                    </a-col>
                  </a-row>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
      <div v-if="permission" class="mt-3 text-right">
        <a-button
          size="large"
          type="primary"
          ghost
          class="px-5 mr-3 ml-auto"
          :disabled="loading"
          @click="goBack"
        >
          {{ $t('utils.cancel') }}
        </a-button>

        <a-button
          size="large"
          type="primary"
          class="px-5"
          :loading="loading"
          :disabled="loading"
          @click="submitAllocationStock"
        >
          {{ $t('utils.save') }}
        </a-button>
      </div>
    </a-card>
  </div>
</template>
  
<script>
import { getAllocationStock, getInventoryList, addAllocationStock, getInventoryByProductId } from '@/api/inventory'
import { getBusinessChannel } from '@/api/business'
import { getWarehouses } from '@/api/warehouse'
import { getProductDetail } from '@/api/product'
import InputNumber from '@/components/Input/InputNumber.vue'

export default {
  name: 'StockAllocation',
  components: { InputNumber },
  data() {
    return {
      activeTab: '',
      activeKey: [],
      setStock: '',
      loading: false,
      channels: [],
      inventories: [],
      warehouses: [],
      product: {
        detail: {
          title: '',
        },
        catalogs: [],
        channels: [],
      },
    }
  },
  computed: {
    productId() {
      const id = this.$route.query.id
      return Array.isArray(id) ? id.join(',') : id
    },
    businessId() {
      return String(this.$route.query['business_id'] || '')
    },
    httpHeader() {
      return { 'Business-Id': this.businessId }
    },
    permission() {
      return this.$store.getters['user/can']('product-master').includes('WRITE')
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      Promise.all([
        this.fetchProductDetail(),
        this.fetchWarehouse(),
        this.fetchChannels(),
        this.fetchInventories(),
      ])
      .then(response => {
        let warehouses = []
        this.activeKey = []
        response[3].forEach((inventory, index) => {
          if(index === 0) this.activeTab = inventory.id
          const warehouse = response[1].find(warehouse => warehouse.warehouse_id == inventory.id)
          let catalogs = []

          inventory.catalogs.forEach(async catalog => {
            let availability = ''
            let allocation = []
            const item = response[0].catalogs.find(item => item.id == catalog.id)
            const bodyRequest = {
              status: 'ALL',
              sort: 'updatedAt,Desc',
              page: 1,
              size: 10,
              search: item.sku,
              business_id: inventory.business_id,
              warehouse_id: inventory.id,
            }
            const bodyValue = {
              warehouse_id: inventory.id,
              business_id: inventory.business_id,
              product_id: response[0].product_id,
              catalog_id: catalog.id,
            }

            await getInventoryList(bodyRequest)
              .then(({ data: { content } }) => {
                const stock = content.find(cat => cat.catalog_id == catalog.id)
                availability = stock?.availability
              })
              .catch(() => availability = '')
            
            await getAllocationStock(bodyValue)
              .then(({ data }) => {
                data.forEach(element => {
                  const stock = response[2].find(channel => channel.id == element.channel_id)
                  const findChannel = this.$store.state.business.listChannels.find(channel => channel.id == element.channel_id)
                  allocation.push({
                    ...element,
                    name: stock?.title || 'No Name',
                    channel_code: stock?.code || '',
                    sales_channel: findChannel?.sales_channel?.name || '-',
                    allocate_value: element?.allocate_value || 0,
                  })
                })
              })
              .catch(() => allocation = [])

            catalogs.push({
              ...catalog,
              name: item?.title || 'No Name',
              sku: item?.sku || '',
              availability,
              allocation,
            })

            if(index === 0) this.activeKey.push(catalog.id)
          })
          
          warehouses.push({
            ...inventory,
            name: warehouse?.name || 'No Name',
            catalogs,
          })
        })
        
        this.product = response[0]
        this.warehouses = warehouses
        this.channels = response[2]
        this.inventories = response[3]
      })
      .catch(err => {
        this.activeTab = ''
        this.product = []
        this.warehouses = []
        this.channels = []
        this.inventories = []
        this.activeKey = []
        console.error(err)
      })
      .finally(() => this.loading = false)
    },
    async fetchProductDetail() {
      if (!this.productId) return
      try {
        const { data: { data } } = await getProductDetail({ 
          id: this.productId,
          params: { business_id: this.businessId },
        })
        return data
      } catch {
        return {
          detail: {
            title: '',
          },
          catalogs: [],
          channels: [],
        }
      }
    },
    async fetchWarehouse() {
      try {
        const { data: { data } } = await getWarehouses({ business_id: this.businessId })
        return data?.data || []
      } catch {
        return []
      }
    },
    async fetchChannels() {
      try {
        const { data: { data } } = await getBusinessChannel(this.businessId)
        return data?.channels || []
      } catch {
        return []
      }
    },
    async fetchInventories() {
      try {
        const { data: { data }} = await getInventoryByProductId({
          product_id: this.productId,
          params: { business_id: this.businessId },
        })
        return data?.warehouses || []
      } catch {
        return []
      }
    },
    async submitAllocationStock() {
      this.loading = true
      let base_uom = ''
      let datas = []
      this.warehouses.forEach(warehouse => {
        let items = []
        warehouse.catalogs.forEach(catalog => {
          base_uom = catalog.uom
          catalog.allocation.forEach(stock => {
            const { catalog_id, channel_id, channel_code, allocate_value, availability } = stock
            items.push({ catalog_id, channel_id, channel_code, allocate_value, availability: availability === null || availability === undefined ? 0 : availability })
          })
        })

        if(items.length) {
          datas.push({
            warehouse_id: warehouse.id,
            items,
          })
        }
      })
      const data = {
        request_id: this.createUUID(),
        business_id: this.businessId,
        product_id: this.productId,
        base_uom,
        datas,
      }
      await addAllocationStock(data)
        .then(() => {
          this.$notification.success({
            message: 'Alokasi Stok berhasil disimpan',
          })
        })
        .catch(err => {
          this.$notification.error({
            message: 'Alokasi Stok gagal disimpan',
          })
          console.error(err)
        })
        .finally(() => this.loading = false)
    },
    createUUID(){
      let dt = new Date().getTime()
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (dt + Math.random()*16)%16 | 0
        dt = Math.floor(dt/16)
        return (c=='x' ? r :(r&0x3|0x8)).toString(16)
      })
      return uuid
    },
    changeTab(tab) {
      this.activeKey = []
      const findWarehouse = this.warehouses.find(warehouse => warehouse.id == tab)
      findWarehouse.catalogs.forEach(item => {
        this.activeKey.push(item.id)
      })
    },
    calculateStock(val, windex, cindex, sindex) {
      const result = Math.round(+this.warehouses[windex].catalogs[cindex].availability * +val / 100)
      this.warehouses[windex].catalogs[cindex].allocation[sindex].allocate_value = val
      this.warehouses[windex].catalogs[cindex].allocation[sindex].availability =  result
    },
    calculateAllStock() {
      this.warehouses.forEach((warehouse, windex) => {
        warehouse.catalogs.forEach((catalog, cindex) => {
          catalog.allocation.forEach((stock, sindex) => {
            this.calculateStock(this.setStock, windex, cindex, sindex)
          })
        })
      })
    },
    formatNumber(value) {
      if(value) return new Intl.NumberFormat("id-ID").format(value)
      return 0
    },
    callback(val) {
    },
    goBack() {
      // eslint-disable-next-line no-unused-vars
      const { id, ...query } = this.$route.query
      this.$router.push({
        path: '/product',
        query,
      })
    },
  },
}
</script>

<style lang="scss">
.ptb-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.allocation {
  &-title i {
    margin-right: 0.5rem;
    font-size: 1rem;
    vertical-align: middle;
    cursor: pointer;
  }

  &-card {
    .ant-card-body {
      padding: 12px 24px
    }

    .ant-skeleton-title,
    .ant-skeleton-paragraph {
      width: 25% !important;
      margin: 0 !important;
    }

    .ant-tabs-ink-bar {
      background-color: #ff0a54;
    }
  }

  &-scroll {
    overflow-y: auto;
  }

  &-form {
    font-weight: bold;
    color: #666;
  }

  &-tabs {
    .ant-tabs-tab {
      font-size: 15px;

      &:hover {
        color: #ff0a54 !important;
      }
    }
    .ant-tabs-tab-active {
      font-weight: bold;
      color: #ff0a54 !important;
    }
  }

  &-header {
    padding: 12px 15px;
    background-color: #E7ECEF;
    color: #666;
    font-weight: bold;
  }

  &-list {
    border-radius: 0 !important;

    .ant-skeleton-title {
      width: 50% !important;
    }

    .title {
      font-weight: bold;
    }
  }
}
.sku-desc {
  font-size: 12px;
  font-weight: 500;
}
</style>
  